import React from "react"
import { Link } from "gatsby"

const MainNav = () => (
  <nav>
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        marginBottom: 20,
      }}
    >
      <Link style={{ marginRight: 20 }} to="/about/">
        About
      </Link>
      <Link style={{ marginRight: 20 }} to="/tags/">
        Tags
      </Link>
      <Link style={{ marginRight: 20 }} to="/rss.xml">
        RSS
      </Link>
    </div>
  </nav>
)

export default MainNav
